<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的积分订单
        </div>
        <div class="vip-content-inner" v-loading="loading" style="min-height: 200px;">
            <div class="deliver-item bill" v-if="item.deliverylist" v-for="(item,index) in ordergoodsList" :key="index">
                 <div class="top-tit">
                     <span>商品名称 :     {{ item.name }}</span>
                     <span>订购量 :     {{ item.quantityPurchase }}</span>
                 </div>
                <div class="tit">物流信息</div>
                <div class="deliver-info-list">
                    <div class="item" v-for="(item1,index1) in item.deliverylist" :key="index1">
                        <div class="top">
                            <p>快递公司 {{ item1.expressTypeName }}</p>
                            <p>快递单号 {{ item1.expressNumber }}</p>
                            <p>本次发货量 {{ item1.quantity }}</p>
                        </div>
                        <div class="bot">
                            <img src="@/assets/img/i-deliver.png" alt="">
                            <div class="date">{{item1.ExpressRecord[0].ftime}}</div>
                            <div class="msg" style="flex: 1 !important;">
                                <span @click="checkWL(item1)">{{ item1.ExpressRecord[0].context }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="m-pop" v-if="showDeliverInfo">
            <div class="pop-con" style="max-width: 700px;">
                <div class="title">
                    <h3>物流信息</h3>
                    <div class="close" @click="showDeliverInfo = false"></div>
                </div>
                <div class="inner-con">
                    <div class="deliver-info-list">
                        <div class="item">
                            <div class="top">
                                <p>快递公司 {{ wlDetail.expressTypeName }}</p>
                                <p>快递单号 {{ wlDetail.expressNumber }}</p>
                                <p>本次发货量 {{ wlDetail.quantity }}</p>
                            </div>
                            <div class="bot-list">
                                <div class="bot" v-for="(item,index) in wlDetail.ExpressRecord" :key="index">
                                    <img src="@/assets/img/i-deliver.png" alt="" v-if="index==0">
                                    <div class="poi" v-else></div>
                                    <div class="date">{{ item.ftime }}</div>
                                    <div class="msg" style="flex: 1 !important;">
                                        <span>{{ item.context }}</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    integralorderdeliverylist, //发货记录
    integralorderdeliverydetail, //发货记录详情
    integralorderdeliveryExpressRecord, //快递记录详情
} from "@/api/transaction";
import {orderIntegralgoodslist} from "@/api/Product";

export default {
    name: "goodsDeliveryInfo",
    data() {
        return {
            showDeliverInfo: false,
            orderId:"",
            loading:true, // 加载状态

            ordergoodsList:[], //订单商品列表
            deliveryList:[], // 发货记录列表
            wlDetail:"", // 快递信息
        }
    },
    mounted() {
        if(this.$route.query){
            console.log(this.$route.query)
            this.orderId=this.$route.query.id
            let goodsId=this.$route.query.goodsId || ''
            this.getorderGoods(goodsId) //获取订单商品

        }

    },
    methods:{
        checkWL(item){
            this.wlDetail=item
            this.showDeliverInfo = true
        },
        //    ----------------接口请求
        //    获取订单商品
        getorderGoods(goodsId){
            let param={
                // customerId:localStorage.getItem('customId'),
                orderId:this.orderId
            }
            orderIntegralgoodslist(param).then((res) => {
                if (res.data.code==200) {
                    // console.log('订单列表',res.data.data)
                    let ordergoodsList=[]
                    if(goodsId){
                        res.data.data.forEach((i,index)=>{
                            if(goodsId == i.integralGoodsId){
                                this.getOrderdeliverylist(i.integralGoodsId,index) //获取发货记录
                                ordergoodsList.push(i)
                            }
                        })
                    }else{
                        res.data.data.forEach((i,index)=>{
                            this.getOrderdeliverylist(i.integralGoodsId,index) //获取发货记录
                        })
                        ordergoodsList=res.data.data
                    }
                    this.ordergoodsList=res.data.data
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            })
        },
        // 获取发货记录
        getOrderdeliverylist(goodsId,index){
            let param={
                customerId:localStorage.getItem('customId'),
                orderId:this.orderId,
                // goodsId:goodsId
                integralGoodsId:goodsId
            }
            // console.log(param)

            integralorderdeliverylist(param).then((res) => {
                if (res.data.code==200) {
                    console.log('发货记录列表',res.data.data)
                    if(res.data.data&&res.data.data.length>0){
                        res.data.data.forEach((i,index1)=>{
                            // i.createTime=i.createTime.slice(0,10)
                            // this.getOrderdeliveryExpressRecord(i.expressNumber) // 物流单号
                            this.getOrderdeliveryExpressRecord(i.id,index,index1) // 物流单号
                            this.ordergoodsList[index].deliverylist=res.data.data
                        })
                    }else{
                        this.loading=false
                        this.$message({
                            type: 'warning',
                            message: '未查询到数据',
                            duration: 2000
                        });
                    }

                }
            })
        },
        // 获取快递信息
        getOrderdeliveryExpressRecord(id,index,index1){
            let param={
                // customerId:localStorage.getItem('customId'),
                // orderId:this.orderId,
                // expressNumber:expressNumber
                id:id
            }
            // console.log(param)
            integralorderdeliveryExpressRecord(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    console.log('快递信息',res.data.data)

                    res.data.data.forEach(i=>{
                        // i.createTime=i.createTime.slice(0,10)
                        this.ordergoodsList[index].deliverylist[index1].ExpressRecord=res.data.data
                    })
                    this.loading=false
                    this.$forceUpdate()
                    console.log(this.ordergoodsList)
                    // this.deliveryList=res.data.data.records
                }
            }).catch(err=>{
                this.loading=false
            })
        },

    },
}
</script>

<style scoped>

</style>
