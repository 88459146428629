import { render, staticRenderFns } from "./billDeliveryInfo.vue?vue&type=template&id=3fdbe462&scoped=true"
import script from "./billDeliveryInfo.vue?vue&type=script&lang=js"
export * from "./billDeliveryInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdbe462",
  null
  
)

export default component.exports